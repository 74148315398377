/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import axios from 'axios'
import Pagina from '../components/Pagina'
import './black-leste.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import logoblackleste from '../assets/icons/black-leste/logo-blackleste.svg'
import back from '../assets/icons/black-leste/back.svg'
import wpp from '../assets/icons/black-leste/whatsapp.svg'
import decoration from '../assets/icons/black-leste/decoration.svg'
import remove from '../assets/icons/black-leste/remove.svg'

import * as queryString from 'query-string'

const BlackLeste = ({ location }) => {
  const data = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }   
      pagina:contentfulPagina(path: { eq: "/black-leste/" }) {
        path
        metaTitle
        metaDescription
        tags
      }
      allCloudOfertasTabloide(filter: {tariff: {name: {eq: "BLACK-LESTE"}}}) {
        edges {
          node {
            offer {
              id
              timeToShow
              image {
                url
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
              brand {
                image {
                  url
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
  `
  )

  let offers
  data.allCloudOfertasTabloide.edges.forEach((value) => {
    offers = value.node.offer
  })

  const [allFavoriteOffers, setAllFavoriteOffers] = useState([])
  const [user, setUser] = useState('')
  const [storeNumber, setstoreNumber] = useState('')
  console.log(storeNumber)
  const regex = /[^0-9]+/g
  const validateStoreNumber = ''

  const eventHandler = async (offer) => {
    const userId = window.localStorage.getItem('userId')
    const payload = {
      image: offer.image.url
    }
    const offerCopy = JSON.parse(JSON.stringify(offer))
    const config = {
      headers: {
        accessToken: process.env.GATSBY_CLOUDOFERTAS_SITE_KEY
      }
    }
    try {
      if (offer.favorito === true) {
        offer.favorito = false
        await axios.delete(`${process.env.GATSBY_CLOUDOFERTAS_API_BOOKLET}/organizations/1/sites/42100970-3f88-4515-b060-8c15c7bb3133/users/${userId}/offers/${offer.id}`, config)
        console.log('delete')
        notify()
      } else {
        offer.favorito = true
        await axios.post(`${process.env.GATSBY_CLOUDOFERTAS_API_BOOKLET}/organizations/1/sites/42100970-3f88-4515-b060-8c15c7bb3133/users/${userId}/offers/${offer.id}`, payload, config)
        console.log('post')
      }
    } catch (err) {
      console.log('ERRO: ', err)
      offer.favorito = offerCopy.favorito
    }
  }

  const favoriteOffers = async () => {
    const { user } = queryString.parse(location.search)
    if (typeof window !== 'undefined') {
      const userId = user || window.localStorage.getItem('userId')
      setUser(userId)
      const store = JSON.parse(window.localStorage.getItem('currentStore'))
      if (store && store.whatsapp) {
        setstoreNumber(store.whatsapp)
      }
      const config = {
        headers: {
          accessToken: process.env.GATSBY_CLOUDOFERTAS_SITE_KEY
        }
      }

      const getFavoriteOffer = await axios.get(`${process.env.GATSBY_CLOUDOFERTAS_API_BOOKLET}/organizations/1/sites/42100970-3f88-4515-b060-8c15c7bb3133/users/${userId}`, config)
      const arrayOffers = []
      offers.forEach((offer) => {
        const favoriteMatch = getFavoriteOffer.data.find((data) => {
          return data.offer === offer.id
        })

        if (favoriteMatch) {
          offer.favorito = true
          arrayOffers.push(offer)
        }
      })
      setAllFavoriteOffers(arrayOffers)
    }
  }

  useEffect(async () => {
    favoriteOffers()
  }, [])

  const notify = () => toast.error('Oferta removida do carrinho', {
    theme: 'colored',
    hideProgressBar: true,
    icon: <img src={remove} />
  })

  return (
    <Fragment>
      <Pagina pagina={data.pagina} />
      <ToastContainer hideProgressBar theme="colored" />
      <main id="blackleste" className='favorites'>
        <div className='background-blue'>
          <div className='d-flex justify-content-center pt-5'>
            <img src={logoblackleste} width='510px' className='position-absolute logoBlackLeste d-md-block d-none' alt='Logo Drogaleste' />
            <img src={logoblackleste} width='320px' className='position-absolute logoBlackLeste d-md-none d-block' alt='Logo Drogaleste' />
          </div>
        </div>
        <div className='marginLogo'></div>
        <div className='pt-3 pb-4 text-center meuCarrinho'>
          <h3>Meu Carrinho</h3>
          <h5><b>Falta pouco para finalizar a sua compra!</b></h5>
          <h5>Envie seus itens no whatsapp da loja.</h5>
        </div>
        <div className='d-flex justify-content-center'>
          <div className='container ofertas row d-flex'>
            {
              allFavoriteOffers.map((item, index) => {
                return (
                  <div className='card d-flex justify-content-end m-3' key={`allFavoriteOffers-${index}`}>
                    {/* <div className='d-flex justify-content-end'>
                    <button className={`star ${item.favorito === true ? 'starActive' : ''}`}></button>
                  </div> */}
                    <div>
                      <GatsbyImage image={item.image.childImageSharp.gatsbyImageData} className='m-2' alt='Oferta favoritada' />
                      <button className='mx-2 px-2 mb-2 add' onClick={() => eventHandler(item)}>Remover do Carrinho</button>
                    </div>
                    {/* {nowDate >= offerDate
                      ? <GatsbyImage image={item.image.childImageSharp.gatsbyImageData} className='m-2' alt='Oferta favoritada' />
                      : <GatsbyImage image={item.brand.image.childImageSharp.gatsbyImageData} className='m-2' alt='Marca da oferta favoritadas' />
                    } */}
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <Link to='/black-leste/' className='back'><img src={back} />ver mais ofertas</Link>
        </div>
        <div className='whatsButton container pb-4'>
          {storeNumber
            ? <button><a href={`https://api.whatsapp.com/send?phone=55${storeNumber.replace(regex, '')}&text=Olá! Segue o link do meu pedido das ofertas da Black Friday que eu quero comprar. MEU CARRINHO:%20${data.site.siteMetadata.siteUrl}/favoritos/?user=${user}`} target="_blank" rel="noreferrer" className='wpp'><img src={wpp} />ENVIAR PEDIDO PARA LOJA MAIS PRÓXIMA</a></button>
            : <button><a href={`https://api.whatsapp.com/send?text=FAVORITOS:%20${data.site.siteMetadata.siteUrl}/favoritos/?user=${user}`} target="_blank" rel="noreferrer" className='wpp'><img src={wpp} />ENVIAR PEDIDO PARA LOJA MAIS PRÓXIMA</a></button>
          }
        </div>
      </main>
    </Fragment>
  )
}
export default BlackLeste
